import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1280.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">
          
          
<path d="M7708 8653 l-48 -4 0 -890 0 -889 58 1 c209 1 453 19 517 36 215 59
385 219 464 438 39 109 40 133 1 37 -84 -207 -245 -365 -440 -433 -52 -19 -87
-23 -175 -24 -99 0 -116 3 -165 26 -85 41 -135 91 -174 172 -56 119 -58 136
-63 592 -5 467 3 565 51 671 93 203 299 274 539 184 161 -60 318 -211 397
-381 16 -35 29 -57 30 -49 0 8 -23 62 -51 119 -44 89 -63 116 -138 190 -67 68
-103 95 -163 124 -135 64 -209 79 -413 82 -99 1 -201 1 -227 -2z"/>
<path d="M6181 7920 c-16 -63 -72 -148 -116 -177 -44 -29 -114 -45 -168 -37
l-42 5 55 -32 c200 -116 242 -142 246 -154 3 -7 -52 -125 -122 -261 l-128
-249 35 -3 c75 -5 102 -15 139 -49 52 -46 80 -93 101 -167 17 -60 18 -47 18
557 1 339 0 617 -2 617 -2 0 -9 -23 -16 -50z"/>
<path d="M4810 7158 c-98 -7 -135 -17 -194 -52 -62 -36 -113 -97 -130 -157
-20 -65 -20 -97 0 -179 35 -151 141 -261 372 -389 50 -28 97 -51 103 -51 6 0
-30 27 -80 60 -122 80 -201 162 -248 257 -34 68 -38 85 -38 152 0 63 5 86 28
133 68 139 219 209 420 195 51 -4 103 -9 117 -12 l25 -6 -25 11 c-67 30 -232
48 -350 38z"/>
<path d="M6192 6068 c-4 -37 -36 -115 -61 -150 -57 -80 -153 -100 -264 -54
l-57 24 87 -91 c47 -51 108 -124 134 -164 83 -128 143 -301 156 -451 12 -145
23 -78 22 135 -2 232 -14 780 -17 751z"/>
<path d="M7660 5020 c0 -593 3 -900 10 -900 7 0 10 220 10 639 0 659 4 744 41
858 45 138 156 237 310 276 l52 14 -144 6 c-79 4 -174 7 -211 7 l-68 0 0 -900z"/>
<path d="M4795 5238 c63 -45 163 -158 193 -217 110 -220 32 -459 -182 -564
-66 -33 -169 -57 -242 -57 -65 0 -202 24 -284 49 -35 11 -66 19 -67 17 -2 -2
23 -13 54 -26 266 -103 544 -88 683 37 92 83 130 182 130 341 0 116 -26 202
-81 272 -44 55 -144 134 -200 156 l-34 14 30 -22z"/>
<path d="M6199 4840 c-4 -8 -14 -61 -23 -118 -39 -249 -175 -519 -355 -705
l-56 -59 46 31 c146 97 259 95 351 -7 l36 -40 7 392 c7 391 5 534 -6 506z"/>
<path d="M5545 3806 c-154 -93 -398 -170 -629 -197 -140 -16 -419 -7 -591 21
-143 23 -242 46 -420 99 -54 17 -101 29 -103 27 -14 -15 491 -228 633 -266
124 -34 303 -38 488 -11 270 40 352 70 484 177 79 64 222 193 213 193 -3 -1
-36 -20 -75 -43z"/>
<path d="M6447 3571 c110 -105 212 -127 563 -118 193 4 235 8 293 26 68 22
154 82 185 129 l14 22 -558 0 -559 -1 62 -58z m403 19 c11 -11 20 -31 20 -45
0 -33 -42 -75 -75 -75 -33 0 -75 42 -75 75 0 36 33 65 75 65 22 0 43 -8 55
-20z"/>


        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
